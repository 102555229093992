import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import Seo from "../components/seo"
import "../styles/components/global/site-map-list.scss"
import "../styles/components/global/block--page-top-content--sitemap.scss"

const query = graphql`
  {
    site {
      siteMetadata {
        siteMap {
          pageName
          pageUrl
          subPages {
            pageName
            pageUrl
            id
          }
          id
        }
      }
    }
  }
`

const SiteMapPage = () => {
  const {
    site: {
      siteMetadata: { siteMap: siteMapData },
    },
  } = useStaticQuery(query)

  return (
    <>
      <Header />
      <Layout showFooter={false}>
        <Seo
          title="Sitemap"
          description="Explore our website"
          pageClass="page--sitemap"
        />

        <Container classes="block--page-top-content block--page-top-content--sitemap">
          <FieldText>
            <h1 className="text">Sitemap</h1>
            <ul className="list list--sitemap">
              {siteMapData.map(page => {
                if (page.hasOwnProperty("subPages")) {
                  return (
                    <li key={page.id}>
                      <Link className="map-link" to={page.pageUrl}>
                        {page.pageName}
                      </Link>
                      <ul className="list list--sitemap list--sitemap--sublist">
                        {page.subPages &&
                          page.subPages.map(subPage => {
                            return (
                              <li key={subPage.id}>
                                <Link
                                  className="map-link sub--link"
                                  to={subPage.pageUrl}
                                >
                                  {subPage.pageName}
                                </Link>
                              </li>
                            )
                          })}
                      </ul>
                    </li>
                  )
                } else {
                  return (
                    <li className="map-link" key={page.id}>
                      <Link to={page.pageUrl}>{page.pageName}</Link>
                    </li>
                  )
                }
              })}
            </ul>
          </FieldText>
        </Container>
      </Layout>
    </>
  )
}

export default SiteMapPage
